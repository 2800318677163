import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Box, Button, Container, Grid, Link, Stack, Typography } from '@mui/material';

import Arc from './img/ARC.png';
import Cmn from './img/CMN.png';
import Mont from './img/MONT.png';
import Tour from './img/TOUR.png';

interface FooterProps {}

export default function FooterMonuments({}: FooterProps) {
	const { t, i18n } = useTranslation(['home', 'landing', 'payment', 'header', 'navigation']);
	const router = useRouter();
	const { pathname, asPath, query, locale } = router;
	const onToggleLanguageClick = (newLocale: string) => {
		i18n.changeLanguage(newLocale);
		router.push({ pathname, query }, router.asPath, { locale: newLocale });
	};
	return (
		<Box
			component="footer"
			sx={{
				backgroundColor: '#bcb4ac',
			}}
		>
			<Container maxWidth="xl">
				<Grid
					container
					spacing={5}
					alignItems={'center'}
					alignContent={'center'}
					sx={{
						paddingLeft: '48px',
					}}
				>
					<Grid item>
						<Image
							width={248}
							height={167}
							src={Arc.src}
							alt={'Arc de Triomphe'}
							sizes="100vw"
							style={{
								width: '100%',
								height: 'auto',
							}}
						/>
					</Grid>
					<Grid item>
						<Image
							width={300}
							height={48}
							src={Cmn.src}
							alt={'Centre des Monuments Nationaux'}
							sizes="100vw"
							style={{
								width: '100%',
								height: 'auto',
							}}
						/>
					</Grid>
					<Grid item>
						<Image width={239} height={135} src={Mont.src} alt={'Mont Saint-Michel'} />
					</Grid>
					<Grid item>
						<Image width={193} height={193} src={Tour.src} alt={'La Tour Eiffel'} />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
