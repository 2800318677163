import { UniversalWalletConnector } from '@magiclabs/wagmi-connector';
import { polygon, polygonMumbai } from 'wagmi/chains';

const chainInUse = process.env.NEXT_PUBLIC_ENVIRONMENT === 'DEV' ? polygonMumbai : polygon;

export const rainbowMagicConnector = ({ chains }: any) => ({
	id: 'magic',
	name: 'Magic',
	iconUrl: '/images/magicicon.svg',
	iconBackground: '#fff',
	createConnector: () => {
		const connector = new UniversalWalletConnector({
			chains: chains,
			options: {
				apiKey: process.env.NEXT_PUBLIC_MAGIC_API_KEY as string,
				magicSdkConfiguration: {
					network: {
						rpcUrl: process.env.NEXT_PUBLIC_PROVIDER_URL as string, // your ethereum, polygon, or optimism mainnet/testnet rpc URL
						chainId: chainInUse.id,
					},
					testMode: process.env.MAGIC_ENABLE_TESTMODE === 'true',
					//locale: 'en',
				},
			},
		});
		return {
			connector,
		};
	},
});
