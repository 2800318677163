import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import imgMagic from './img/magic.png';
import FormBox from '../../components/FormBox';
import rainbowStyles from './Rainbow.style';

interface RainbowProps {
	onAccept: () => void;
}

export default function Rainbow({ onAccept }: RainbowProps) {
	const { t } = useTranslation(['home']);
	const noWalletYetText = t('rainBow.noWalletYetText');
	return (
		<FormBox>
			<Stack sx={rainbowStyles.contained}>
				<Typography
					sx={{
						...rainbowStyles.typo,
						...rainbowStyles.title,
						fontSize: 20,
						textTransform: 'uppercase',
						marginBottom: '40px',
						marginTop: '10px',
					}}
				>
					{t('rainBow.connectWalletTitle')}
				</Typography>
				<Typography sx={{ ...rainbowStyles.typo, ...rainbowStyles.title }}>{t('rainBow.noWalletYetTitle')}</Typography>
				<Typography
					sx={rainbowStyles.typo}
					variant="body1"
					component="div"
					dangerouslySetInnerHTML={{ __html: noWalletYetText }}
				/>
				<Typography sx={{ ...rainbowStyles.typo }}>{t('rainBow.autoCreateWalletText')}</Typography>

				<img src={imgMagic.src} alt="magic" style={{ height: '60px', margin: '0px auto 12px' }} />
				<span
					style={{
						borderBottom: '1px solid white',
						width: '20%',
						margin: '20px auto',
					}}
				/>
				<Typography sx={{ ...rainbowStyles.typo, ...rainbowStyles.title }}>
					{t('rainBow.alreadyHaveWalletTitle')}
				</Typography>
				<Typography sx={{ ...rainbowStyles.typo, marginBottom: '30px' }}>
					{t('rainBow.alreadyHaveWalletText')}
				</Typography>

				<Button variant="contained" onClick={onAccept}>
					{t('rainBow.understood')}
				</Button>
			</Stack>
		</FormBox>
	);
}
