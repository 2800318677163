import Router from 'next/router';
import merge from 'lodash.merge';
import NProgress from 'nprogress';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { SessionProvider } from 'next-auth/react';
import { Analytics } from '@vercel/analytics/react';
import { SWRConfig } from 'swr';
import type { AppProps } from 'next/app';
import type { Session } from 'next-auth';
import { appWithTranslation } from 'next-i18next';
// import { ChakraProvider, Spacer } from '@chakra-ui/react';
import { ThemeProvider } from '@mui/system';
import {
	connectorsForWallets,
	darkTheme,
	DisclaimerComponent,
	RainbowKitProvider,
	Theme,
} from '@rainbow-me/rainbowkit';
import {
	coinbaseWallet,
	injectedWallet,
	metaMaskWallet,
	rainbowWallet,
	walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import '@rainbow-me/rainbowkit/styles.css';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { polygon, polygonMumbai } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';

import { rainbowMagicConnector } from '../utils/RainbowMagicConnector';

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';

// import GlobalStyles from '../themes/globalStyles';
import theme from '../themes/theme';
import './styles.css';
import '../themes/nprogress.css';
import Layout from '../components/Layout';

const chainInUse = process.env.NEXT_PUBLIC_ENVIRONMENT === 'DEV' ? polygonMumbai : polygon;
if (chainInUse === undefined) {
	throw new Error('Expected chainInUse');
}
const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;
if (projectId === undefined) {
	throw new Error('Expected projectId');
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
	[chainInUse],
	[
		jsonRpcProvider({
			rpc: (chain) => ({
				http: process.env.NEXT_PUBLIC_PROVIDER_URL as string,
			}),
			// stallTimeout: 1_000,
		}),
		alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_ID as string }),
		publicProvider(),
	]
);

const connectors = connectorsForWallets([
	{
		groupName: 'Recommended for new users',
		wallets: [rainbowMagicConnector({ chains })],
	},
	{
		groupName: 'Others',
		wallets: [
			injectedWallet({ chains }),
			metaMaskWallet({ chains, projectId }),
			walletConnectWallet({ projectId: projectId, chains: chains }),
			rainbowWallet({ projectId: projectId, chains: chains }),
			coinbaseWallet({ chains: chains, appName: '101Experience Early' }),
		],
	},
]);

const wagmiConfig = createConfig({
	autoConnect: true,
	connectors,
	publicClient,
});

const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
	<>
		<Text>
			<h3 style={{ fontSize: '1.4em', color: 'whitesmoke' }}>
				If you don't have a wallet, you can choose <span style={{ color: '#6851FF' }}>Magic</span> to automatically have
				one linked with your email.
			</h3>
		</Text>
		<Text>
			By connecting your wallet, you agree to the <Link href="/policy">Privacy Policy</Link> &{' '}
			<Link href="/terms">Terms of Service</Link>
		</Text>
	</>
);

const myTheme = merge(
	darkTheme({
		//accentColor: 'linear-gradient(225.73deg, #d0c6bf 0%, #cab5a5 20.71%, #A38471 100%)',
		accentColorForeground: 'black',
		borderRadius: 'small',
		//	fontStack: 'system',
		overlayBlur: 'small',
	}),
	{
		fonts: {
			body: 'RigidSquare, system-ui, sans-serif',
		},
		colors: {
			accentColor: 'white',
			/*connectButtonInnerBackground: 'linear-gradient(225.73deg, #d0c6bf 0%, #cab5a5 20.71%, #A38471 100%)',
			connectButtonBackground: 'linear-gradient(225.73deg, #d0c6bf 0%, #cab5a5 20.71%, #A38471 100%)',
			connectButtonBackgroundError: 'linear-gradient(225.73deg, #d0c6bf 0%, #cab5a5 20.71%, #A38471 100%)',
			connectButtonText: 'linear-gradient(225.73deg, #d0c6bf 0%, #cab5a5 20.71%, #A38471 100%)',*/
		},
	} as Theme
);

Router.events.on('routeChangeStart', (url) => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.
const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps<{ session: Session }>) => {
	return (
		<SessionProvider session={session} refetchInterval={0}>
			<WagmiConfig config={wagmiConfig}>
				<SWRConfig
					value={{
						onError: (error, key) => {
							if (error.status !== 403 && error.status !== 404) {
								throw error;
							}
						},
					}}
				>
					<RainbowKitProvider
						modalSize="compact"
						coolMode
						showRecentTransactions={false}
						theme={myTheme}
						chains={chains}
						appInfo={{
							appName: 'The 101Experience VIP Early Access',
							learnMoreUrl: 'https://vip.the101experience.com',
							disclaimer: Disclaimer,
						}}
					>
						<ThemeProvider theme={theme}>
							{/*<ChakraProvider theme={theme}>*/}
							{/*<GlobalStyles />*/}
							<GoogleAnalytics trackPageViews />
							<Layout>
								<Component {...pageProps} />
								<Analytics />
							</Layout>
							{/*</ChakraProvider>*/}
						</ThemeProvider>
					</RainbowKitProvider>
				</SWRConfig>
			</WagmiConfig>
		</SessionProvider>
	);
};

export default appWithTranslation(App);
