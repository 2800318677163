import { createTheme } from '@mui/material';

export const colorOr = '#AD9579';

const theme = createTheme({
	palette: {
		primary: {
			main: colorOr,
			light: '#FFFFFF',
			dark: '#161C20',
		},
	},
	components: {
		MuiIconButton: {
			styleOverrides: {
				root: {
					border: 'none',
					color: colorOr,
					fontSize: '20px',
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: colorOr,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) =>
					({
						borderRadius: '25px',
						textTransform: 'uppercase',
						fontSize: 14,
						height: '40px',
						fontWeight: 500,
						...(ownerState.variant === 'text' && {
							textTransform: 'none',
							fontSize: 18,
							textDecoration: 'underline',
						}),
						...(ownerState.variant === 'outlined' && {
							color: colorOr,
							borderColor: colorOr,
							borderWidth: '1px',
							borderStyle: 'solid',
							'&:hover': {
								borderColor: colorOr,
								backgroundColor: 'black',
								color: colorOr,
								borderWidth: '1px',
								borderStyle: 'solid',
							},
						}),
						...(ownerState.variant === 'contained' && {
							color: '#161C20',
							borderColor: 'whitesmoke',
							backgroundColor: 'white',
							borderWidth: '1px',
							borderStyle: 'solid',
							'&:hover img': {
								filter: 'invert(70%) sepia(70%)',
							},
							'&:hover': {
								borderColor: colorOr,
								backgroundColor: 'black',
								color: colorOr,
								borderWidth: '1px',
								borderStyle: 'solid',
							},
						}),
					}) as const,
			} as const,
		},
	},
	typography: {
		allVariants: {
			fontFamily: 'RigidSquare',
		},
		fontSize: 15,
		h1: {
			fontWeight: 500,
			lineHeight: 1.1,
			color: 'white',
			fontSize: 20,
		},
		h2: {
			fontSize: '1.2rem',
			fontWeight: 500,
			lineHeight: 1.1,
			color: 'white',
		},
		h3: {
			fontSize: '1rem',
			fontWeight: 500,
			lineHeight: 1.1,
			color: 'white',
		},
		h4: {
			fontSize: '1rem',
			fontWeight: 400,
			lineHeight: 1,
			color: 'white',
		},
		h5: {
			fontSize: '0.9rem',
			fontWeight: 400,
			lineHeight: 1,
			color: 'white',
		},
		h6: {
			fontSize: '0.9rem',
			fontWeight: 300,
			lineHeight: 1,
			color: 'white',
		},
		body1: {
			lineHeight: 1.1,
			color: 'white',
		},
		body2: {
			lineHeight: 1.1,
			fontSize: '0.8rem',
			color: 'white',
		},
	},
});
//theme = responsiveFontSizes(theme);

export default theme;
