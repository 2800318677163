import { Button, Stack, Typography } from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useAccount } from 'wagmi';
import { useSession } from 'next-auth/react';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FormBox from '../../components/FormBox';
import TextField from '../../components/TextField';
import { User } from '../../models/user';
import { fetchUserProfile } from '../../utils/account';

type PropsProfileField = {
	isEdit: boolean;
	setEdit: (val: boolean) => void;
	name: 'name' | 'email' | 'password' | 'language';
	label: string;
	user: User;
};

function ProfileField({ isEdit, setEdit, name, label, user }: PropsProfileField) {
	const iconStyle = {
		width: '20px',
		height: '20px',
		cursor: 'pointer',
		color: 'primary.main',
	};

	const { setFieldValue, submitForm } = useFormikContext();

	return (
		<Stack direction="row" alignItems="center" spacing={1}>
			<TextField name={name} label={label} readOnly={!isEdit} darkMode />
			{!isEdit && <EditIcon sx={{ ...iconStyle }} onClick={() => setEdit(true)} />}
			{isEdit && (
				<>
					<CheckIcon
						sx={{ ...iconStyle }}
						onClick={() => {
							void submitForm().then(() => setEdit(false));
						}}
					/>
					<CloseIcon
						sx={{ ...iconStyle }}
						onClick={() => {
							void setFieldValue(name, user[name]).then(() => setEdit(false));
						}}
					/>
				</>
			)}
		</Stack>
	);
}

export default function Profile() {
	const { address, isConnected } = useAccount();
	const { data: session } = useSession();

	const [user, setUser] = useState<User | undefined>(undefined);
	const [editUserName, setEditUserName] = useState<boolean>(false);
	const [editEmail, setEditEmail] = useState<boolean>(false);
	const [editPassword, setEditPassword] = useState<boolean>(false);
	const [editLanguage, setEditLanguage] = useState<boolean>(false);

	const { t } = useTranslation(['profile', 'home', 'navigation']);

	// TODO change me with SWR Update call
	const {
		user: data,
		error: error,
		isLoading: isLoading,
		isValidating: isValidating,
		mutate: updateProfile,
	} = fetchUserProfile({
		address: address,
		email: session?.user?.email,
	});

	useEffect(() => {
		const connectedUser = JSON.parse(localStorage.getItem('user') as string) as User | undefined;
		if (connectedUser) {
			setUser(connectedUser);
		}
	}, []);

	const lineStyle = {
		borderColor: '#464545',
		borderWidth: '0px',
		borderTopWidth: '2px',
		borderStyle: 'dotted',
		width: '428px',
		ml: -4,
	};

	return (
		<FormBox>
			<Stack>
				<Stack direction="row" justifyContent="center" m={2} mb={4}>
					<Typography variant="h1" color="primary.main">
						{t('title')}
					</Typography>
				</Stack>

				{user && (
					<Formik
						initialValues={{ ...user }}
						onSubmit={(userToSubmit) => {
							updateProfile(userToSubmit);
						}}
					>
						<Form>
							<ProfileField
								isEdit={editUserName}
								setEdit={setEditUserName}
								name="name"
								label={t('userName')}
								user={user}
							/>
							<Stack
								sx={{
									...lineStyle,
								}}
							/>
							<ProfileField isEdit={editEmail} setEdit={setEditEmail} name="email" label={t('email')} user={user} />
							<Stack
								sx={{
									...lineStyle,
								}}
							/>
							<ProfileField
								isEdit={editPassword}
								setEdit={setEditPassword}
								name="password"
								label={t('password')}
								user={user}
							/>
							<Stack
								sx={{
									...lineStyle,
								}}
							/>
							<ProfileField
								isEdit={editLanguage}
								setEdit={setEditLanguage}
								name="language"
								label={t('language')}
								user={user}
							/>
						</Form>
					</Formik>
				)}
				<Stack direction="row" justifyContent="center" m={2} mt={16}>
					<Button
						variant="contained"
						onClick={() => {
							localStorage.clear();
							window.location.reload();
						}}
					>
						{t('logout', { ns: 'navigation' })}
					</Button>
				</Stack>
			</Stack>
		</FormBox>
	);
}
