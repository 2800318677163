import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Typography, Stack, Button, InputLabel, NativeSelect } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { signIn, signOut, useSession } from 'next-auth/react';
import { FormControl } from '@mui/base';
import { isMobile } from 'react-device-detect';

import Profile from '../../Profile';

type Props = {
	closeNavigation: () => void;
};

export default function Navigation({ closeNavigation }: Props) {
	const { t, i18n } = useTranslation(['navigation'], { bindI18n: 'languageChanged loaded' });
	const [profileMode, setProfileMode] = useState<boolean>(false);

	const router = useRouter();
	const { pathname, asPath, query, locale } = router;

	function navigate(route: string) {
		router.push({ pathname: route }, route, { locale: locale });
		closeNavigation();
	}

	const onToggleLanguageClick = (e: any) => {
		const newLocale = e.target.value;
		i18n.changeLanguage(newLocale);
		router.push({ pathname, query }, router.asPath, { locale: newLocale });
	};

	useEffect(() => {
		i18n.reloadResources(i18n.resolvedLanguage, ['navigation']);
	}, [i18n.language]);

	const { data: session } = useSession();

	const itemStyle = {
		fontWeight: 'normal',
		textTransform: 'uppercase',
		fontSize: isMobile ? 18 : 25,
		color: 'primary.main',
	};

	return (
		<>
			{profileMode ? (
				<Profile />
			) : (
				<Stack
					direction="column"
					spacing={isMobile ? 1 : 2}
					justifyContent="center"
					alignItems="flex-start"
					pt={isMobile ? 4 : 0}
				>
					{/*<Button sx={{ ...itemStyle }} variant="text" onClick={() => setProfileMode(true)}>
						{t('profile', { ns: 'navigation' })}
					</Button>*/}
					<Button
						sx={{ ...itemStyle }}
						variant="text"
						onClick={(e) => {
							navigate('/');
						}}
					>
						{t('home', { ns: 'navigation' })}
					</Button>
					{/*<Button sx={{ ...itemStyle }} variant="text">
						{t('collection', { ns: 'navigation' })}
					</Button>*/}
					<Typography sx={{ ...itemStyle }} px={1} variant="h1" color="primary.main">
						................
					</Typography>
					{/*<Button
						sx={{ ...itemStyle }}
						variant="text"
						onClick={(e) => {
							navigate('/faq');
						}}
					>
						{t('faq', { ns: 'navigation' })}
					</Button>*/}
					<Button
						sx={{ ...itemStyle }}
						variant="text"
						onClick={(e) => {
							navigate('/about');
						}}
					>
						{t('About Us', { ns: 'navigation' })}
					</Button>
					<Button
						sx={{ ...itemStyle }}
						variant="text"
						onClick={(e) => {
							navigate('/terms');
						}}
					>
						{t('CGU', { ns: 'navigation' })}
					</Button>
					<Button
						sx={{ ...itemStyle }}
						variant="text"
						onClick={(e) => {
							navigate('/policy');
						}}
					>
						{t('CONF', { ns: 'navigation' })}
					</Button>

					<Typography sx={{ ...itemStyle }} px={1} variant="h1" color="primary.main">
						................
					</Typography>

					<FormControl>
						<InputLabel htmlFor="uncontrolled-native" sx={{ ...itemStyle }}>
							{t('choose-language', { ns: 'navigation' })}
						</InputLabel>
						<NativeSelect
							placeholder={t('choose-language', { ns: 'navigation' })}
							defaultValue={locale}
							inputProps={{
								name: 'language',
								id: 'uncontrolled-native',
							}}
							sx={{ ...itemStyle }}
							onChange={onToggleLanguageClick}
						>
							<option value={'fr'}>Français</option>
							<option value={'en'}>English</option>
						</NativeSelect>
					</FormControl>
					{/*<Select
				variant="standard"
				label={t('choose-language', { ns: 'navigation' })}
				onChange={onToggleLanguageClick}
				defaultValue={locale}
				style={{ fontFamily: 'RigidSquare' }}
				sx={{ ...itemStyle }}
			>
				<MenuItem value="fr" sx={{ ...itemStyle }}>
					Français
				</MenuItem>
				<MenuItem value="en" sx={{ ...itemStyle }}>
					English
				</MenuItem>
			</Select>*/}

					<Typography sx={{ ...itemStyle }} px={1} variant="h1" color="primary.main">
						................
					</Typography>

					{session?.user ? (
						<Button
							sx={{ ...itemStyle }}
							variant="text"
							onClick={(e) => {
								e.preventDefault();
								localStorage.clear();
								signOut({
									callbackUrl: `${window.location.origin}`,
								});
							}}
						>
							{t('logout', { ns: 'navigation' })}
						</Button>
					) : (
						<Button
							sx={{ ...itemStyle }}
							variant="text"
							onClick={(e) => {
								e.preventDefault();
								signIn();
							}}
						>
							{t('signIn', { ns: 'navigation' })}
						</Button>
					)}
				</Stack>
			)}
		</>
	);
}
