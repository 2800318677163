import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { isMobile } from 'react-device-detect';
import { useConnectModal, ConnectButton } from '@rainbow-me/rainbowkit';
import { Stack, Box, Button, IconButton, Tooltip, CircularProgress } from '@mui/material';

import { Connector, ConnectorData, useAccount, useBalance, useNetwork, usePublicClient } from 'wagmi';

import MagicIcon from './magicicon.svg';
import LogoHeader from './logo-white.png';

import Burger from './burger.svg';
import PopIn from '../../PopIn';
import Navigation from '../Navigation';
import Rainbow from '../../Rainbow';

import { useOpenWallet } from '../../../hooks/useOpenWallet';
import useKeepState from '../../../hooks/useKeepState';

export default function Header() {
	const { t } = useTranslation(['header', 'home', 'navigation']);
	const router = useRouter();
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [showRainbow, setShowRainbow] = useState(false);

	const [isWalletMagic, setIsWalletMagic] = useState(true);

	const { openConnectModal } = useConnectModal();
	const { loading: loadingOpenWallet, openWallet } = useOpenWallet();

	const [addr, setAddr] = useKeepState(undefined, 'address');
	const [currentConnector, setCurrentConnector] = useState<undefined | Connector>(undefined);

	const [firstConnect, setFirstConnect] = useState(true);
	const {
		address,
		isConnected,
		connector: activeConnector,
	} = useAccount({
		onConnect({ address, connector, isReconnected }) {
			setAddr(address);
			setCurrentConnector(connector);
			setFirstConnect(!isReconnected);
		},
		onDisconnect() {
			setAddr(undefined);
			setFirstConnect(false);
		},
	});
	const { chain } = useNetwork();
	const provider = usePublicClient();
	const balanceMatic = useBalance({
		address: address,
	});

	const { data: session, status } = useSession();

	useEffect(() => {
		if (isConnected && activeConnector?.name === 'Magic') {
			setIsWalletMagic(true);
		} else {
			setIsWalletMagic(false);
		}
	}, [isConnected, activeConnector]);

	//@ts-ignore
	useEffect(() => {
		const handleConnectorUpdate = async ({ account, chain }: ConnectorData) => {
			if (account) {
				console.log('New account connected', account);
				setAddr(account);
			} else if (chain) {
				console.log('New chain detected', chain);
			}
		};
		if (activeConnector) {
			activeConnector.on('change', handleConnectorUpdate);
		}
		return () => activeConnector?.off('change', handleConnectorUpdate);
	}, [activeConnector]);

	useEffect(() => {
		if (
			isConnected &&
			address &&
			addr &&
			(firstConnect || address?.toLowerCase() !== addr?.toLowerCase()) &&
			session?.user?.email &&
			balanceMatic.data?.formatted &&
			status === 'authenticated'
		) {
			axios
				.post('/api/add_account', {
					address: address,
					isActive: true,
					balance: Number(balanceMatic.data?.formatted) ?? 0,
					// balanceRroz: Number(balanceRroz?.formatted) ?? 0,
					email: session?.user?.email,
					chainId: chain?.id,
					chainName: chain?.name,
					chainSymbol: chain?.nativeCurrency.symbol,
					provider: currentConnector?.name ?? provider.constructor.name ?? provider.name,
					timestamp: Date.now(),
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [isConnected, address, addr, session?.user?.email, balanceMatic?.data?.formatted, status, firstConnect]);

	// @ts-ignore
	return (
		<>
			{/*<Stack
				sx={{
					width: '100%',
					height: '40px',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'primary.main',
				}}
			>
				<Typography color="primary.dark">{t('vip', { ns: 'header' })}</Typography>
			</Stack>*/}
			<Stack padding={2} direction="row" justifyContent="space-between" alignItems="center">
				<Box
					component="img"
					sx={{
						width: '136px',
						height: '28px',
					}}
					src={LogoHeader.src}
					onClick={() => router.push({ pathname: '/' })}
				/>
				<Stack direction="row" alignItems="center" spacing={isMobile ? 0 : 2}>
					{isConnected ? (
						session?.user && (
							<>
								<ConnectButton
									label={t('connect-wallet')}
									accountStatus="address"
									chainStatus={{
										smallScreen: 'icon',
										largeScreen: 'full',
									}}
									showBalance={{
										smallScreen: false,
										largeScreen: true,
									}}
								/>

								{isWalletMagic && (
									<Tooltip title={t('Open Magic wallet', { ns: 'landing' })}>
										<IconButton onClick={openWallet}>
											{loadingOpenWallet ? (
												<CircularProgress size={'1.2rem'} />
											) : (
												<Image width={25} height={25} src={MagicIcon} alt="Magic Profile" />
											)}
										</IconButton>
										{/*<Button onClick={openWallet}>Open Magic wallet</Button>*/}
									</Tooltip>
								)}
							</>
						)
					) : // <Button onClick={openConnectModal}>{t('wallet')}</Button>
					session?.user ? (
						<Button
							sx={{ color: 'white' }}
							onClick={() => {
								setShowRainbow(true);
							}}
						>
							{t('wallet')}
						</Button>
					) : (
						<Button
							sx={{ textTransform: 'uppercase', color: 'white' }}
							variant="text"
							onClick={(e) => {
								e.preventDefault();
								signIn();
							}}
						>
							{t('signIn', { ns: 'navigation' })}
						</Button>
					)}
					<Box
						onClick={() => setShowMenu(true)}
						component="img"
						sx={{
							width: '24px',
							height: '40px',
							cursor: 'pointer',
							marginLeft: '5px',
						}}
						src={Burger.src}
					/>
				</Stack>
			</Stack>
			<PopIn open={showMenu} title="" onClose={() => setShowMenu(false)} fullScreen>
				<Navigation closeNavigation={() => setShowMenu(false)} />
			</PopIn>

			{/* Rainbow POPIN */}
			<PopIn onClose={() => setShowRainbow(false)} open={showRainbow} fullScreen>
				<Rainbow
					onAccept={() => {
						if (openConnectModal) {
							setShowRainbow(false);
							openConnectModal();
						}
					}}
				/>
			</PopIn>
		</>
	);
}
