import React, { ReactNode } from 'react';
import { useSession } from 'next-auth/react';
import Script from 'next/script';
import { Global, css } from '@emotion/react';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { User } from '../../models/user';
import Header from './Header';
import Footer from '../Footer';
import FooterMonuments from '../FooterMonuments';

type Props = {
	userConnected?: User | undefined;
};

export default function Layout({ children, userConnected }: { children: ReactNode; userConnected: Props }) {
	const { data: session } = useSession();

	return (
		<>
			<Script
				id="cookieyes"
				type="text/javascript"
				src="https://cdn-cookieyes.com/client_data/0d4dfef6bea7cae190faddfa/script.js"
				strategy="beforeInteractive"
			></Script>
			<Global
				styles={css`
					#app-root {
						min-height: 100vh;
						padding: 0;
						display: flex;
						flex-direction: column;
						font-size: 14px;
						background-color: black;
					}
					@font-face {
						font-family: 'RalewayBold';
						font-display: swap;
						src: url('/fonts/Raleway/Raleway-Bold.ttf');
					}
					/* latin */
					@font-face {
						font-family: 'RalewayMedium';
						font-display: swap;
						src: url('/fonts/Raleway/Raleway-Medium.ttf');
					}
					/* latin */
					@font-face {
						font-family: 'RigidSquare';
						font-display: swap;
						src: url('/fonts/RigidSquare/rigidsquareNEW.ttf');
					}
				`}
			/>
			<CssBaseline />
			<Box
				sx={{
					position: 'fixed',
					width: '100%',
					height: '100%',
					left: 0,
					zIndex: -2,
					backgroundImage: 'url("/images/background_mont.jpeg")',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>
			{session && <Header />}
			<Box sx={{ minHeight: '100vh' }}>{children}</Box>
			{/*<Footer />*/}

			<FooterMonuments />
		</>
	);
}

Layout.defaultProps = {
	userConnected: undefined,
};
