import { InputBase, InputBaseProps, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'next-i18next';

type Props = {
	name: string;
	label: string;
	helpText?: string;
	required?: boolean;
	errorRequired?: boolean;
	readOnly?: boolean;
	darkMode?: boolean;
} & InputBaseProps;

export default function TextField({
	name,
	label,
	helpText,
	required,
	errorRequired,
	readOnly,
	darkMode,
	...rest
}: Props) {
	const [field] = useField<string>({ name });
	const { t } = useTranslation(['landing']);

	return (
		<Stack direction={readOnly || darkMode ? 'row' : 'column'} spacing={darkMode ? 1 : 0}>
			<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
				<Typography
					sx={{
						pt: 2,
						pb: helpText ? 0 : 2,
						width: readOnly || darkMode ? '145px' : 'auto',
						textAlign: readOnly || darkMode ? 'right' : 'left',
						fontSize: '15px',
					}}
				>{`${label}${required ? ' *' : ''}`}</Typography>
				{errorRequired && (
					<Typography color="red" sx={{ fontSize: '15px' }}>
						{t('required', { ns: 'landing' })}
					</Typography>
				)}
			</Stack>
			{helpText && (
				<Typography color="primary.main" sx={{ pb: 2, fontSize: '15px' }}>
					{helpText}
				</Typography>
			)}
			<InputBase
				readOnly={readOnly}
				id={name}
				sx={{
					borderRadius: 2,
					fontSize: '15px',
					minHeight: readOnly || darkMode ? 'auto' : '58px',
					backgroundColor: darkMode ? 'transparent' : 'white',
					color: darkMode ? 'primary.main' : 'black',
					px: 2,
					'&.Mui-focused': {
						border: readOnly ? '0px' : '2px solid',
						borderColor: 'primary.main',
					},
					'&.Mui-readOnly': {
						backgroundColor: 'transparent',
						color: 'primary.main',
					},
				}}
				{...field}
				{...rest}
			/>
		</Stack>
	);
}

TextField.defaultProps = {
	helpText: undefined,
	required: false,
	errorRequired: false,
	readOnly: false,
	darkMode: false,
};
