// Rainbow.style.tsx

import { colorOr } from '../../themes/theme';

const rainbowStyles = {
	contained: {
		textAlign: 'center',
	},
	typo: {
		marginBottom: '20px',
		textAlign: 'center',
	},
	title: {
		color: colorOr,
		fontSize: '1.5rem',
		marginBottom: '10px',
	},
};

export default rainbowStyles;
