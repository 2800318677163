import { Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';

type Props = {
	children?: React.ReactNode;
};

export default function FormBox({ children }: Props) {
	return (
		<Stack
			direction="column"
			padding={isMobile ? 3 : 4}
			sx={{
				backgroundColor: 'primary.dark',
				width: isMobile ? '100%' : '428px',
				borderRadius: '20px 20px 20px 20px',
			}}
		>
			{children}
		</Stack>
	);
}

FormBox.defaultProps = {
	children: <div />,
};
