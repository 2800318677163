import { Magic } from 'magic-sdk';
import { polygon, polygonMumbai } from 'wagmi/chains';

const chainInUse = process.env.NEXT_PUBLIC_ENVIRONMENT === 'DEV' ? polygonMumbai : polygon;

const createMagic = (key: string) => {
	return (
		typeof window !== 'undefined' &&
		new Magic(key, {
			network: {
				rpcUrl: process.env.NEXT_PUBLIC_PROVIDER_URL as string,
				chainId: chainInUse.id,
			},
		})
	);
};

export const magic = createMagic(process.env.NEXT_PUBLIC_MAGIC_API_KEY as string);
