import axios from 'axios';
import useSWR from 'swr';

const fetcher = (url, data) => axios.post(url, data).then((res) => res.data);

const disableCache = (useSWRNext) => {
	return (key, fetcher, config) => {
		const swr = useSWRNext(key, fetcher, config);
		const { data, isValidating } = swr;
		return Object.assign({}, swr, {
			data: isValidating ? undefined : data,
		});
	};
};

export function fetchUserProfile(body) {
	const { data, error, isLoading, isValidating, mutate } = useSWR(
		body.email && body.address ? ['/api/get_account_assets', body] : null,
		([url, body]) => fetcher(url, body)
	);
	return {
		user: data,
		error: error,
		isLoading: isLoading,
		isValidating: isValidating,
		mutate: mutate,
	};
}

export function fetchUserPreOrders(body) {
	const { data, error, isLoading, isValidating, mutate } = useSWR(
		body.owner ? ['/api/get_preorders', body] : null,
		([url, body]) => fetcher(url, body),
		{
			focusThrottleInterval: 0,
			use: [disableCache],
		}
	);
	return {
		preOrders: data,
		error: error,
		isLoading: isLoading,
		isValidating: isValidating,
		mutate: mutate,
	};
}
