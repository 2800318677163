import { Box, Modal, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';

import Close from './close.svg';
import LogoTop from './logotop.svg';

type Props = {
	open: boolean;
	onClose: () => void;
	title?: string | undefined;
	children?: React.ReactNode;
	fullScreen?: boolean;
};

export default function PopIn({ title, open, onClose, children, fullScreen }: Props) {
	return (
		<>
			{open && !isMobile && (
				<Box
					component="img"
					onClick={onClose}
					sx={{
						width: '62px',
						height: '62px',
						cursor: 'pointer',
						position: 'fixed',
						top: 40,
						right: 50,
						zIndex: 9999,
					}}
					src={Close.src}
				/>
			)}
			{open && isMobile && (
				<Box
					component="img"
					onClick={onClose}
					sx={{
						width: '30px',
						height: '30px',
						cursor: 'pointer',
						position: 'fixed',
						top: 20,
						right: 20,
						zIndex: 9999,
					}}
					src={Close.src}
				/>
			)}
			<Modal
				open={open}
				onClose={onClose}
				sx={{
					maxHeight: '100vh',
					overflow: 'auto',
				}}
				componentsProps={{
					backdrop: {
						style: {
							backdropFilter: 'blur(12px)',
							backgroundColor: fullScreen ? 'rgba(22, 28, 32, 0.92)' : 'rgba(173, 149, 121, 0.1)',
						},
					},
				}}
			>
				<>
					<Stack direction="row" justifyContent={'center'} padding={2} spacing={0} alignItems="center">
						<Box
							component="img"
							onClick={onClose}
							sx={
								isMobile
									? {
											width: '136px',
											height: '28px',
									  }
									: {
											width: '180px',
											height: '37px',
									  }
							}
							src={LogoTop.src}
						/>
						{title && <div>{title}</div>}
					</Stack>
					<Stack direction="column" justifyContent="center" alignItems="center">
						{children}
					</Stack>
				</>
			</Modal>
		</>
	);
}

PopIn.defaultProps = {
	children: <div />,
	title: undefined,
	fullScreen: false,
};
